.btn.btn-primary {
    color: #fff;
    background-color: #0bb783;
    border-color: #0bb783;
}

.btn.btn-secondary {
    color: #3f4254;
    background-color: #e4e6ef;
    border-color: #e4e6ef;
}

.btn.btn-success {
    color: #fff;
    background-color: #1bc5bd;
    border-color: #1bc5bd;
}

.btn.btn-danger {
    color: #fff;
    background-color: #f64e60;
    border-color: #f64e60;
}

.btn.btn-warning {
    color: #fff;
    background-color: #ffa800;
    border-color: #ffa800;
}
.btn.btn-link {
    color: #1bc5bd;
}

.btn:focus, .btn:active{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn.btn-primary:focus, .btn.btn-primary:active{
    background-color: #0bb783 !important;
    
}
.btn.btn-secondary:focus, .btn.btn-secondary:active{
    background-color: #e4e6ef !important;
    
}
.btn.btn-success:focus, .btn.btn-success:active{
    background-color: #1bc5bd !important;
    
}
.btn.btn.btn-danger:focus, .btn.btn.btn-danger:active{
    background-color: #f64e60 !important;
    
}
.btn.btn.btn-warning:focus, .btn.btn.btn-warning:active{
    background-color: #ffa800 !important;
    
}

.card.card-custom .card-header{
    background-color: #fff;
    border: none;
    padding-bottom: 0;
}
.card.card-custom .card-header p{
    color: #bdbdbd;
    font-size: 13px;
}

.card.card-custom, .modal-content {
    -webkit-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05);
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05);
    border: 0;
}

.modal-content .modal-header, .modal-content .modal-footer{
    border: 0;
}

.text-muted{
    color: #bdbdbdd1;
}



body {
    background: #F7F7F7;
    font-weight: 500;
}

.side-nav .nav-link{
    color: #969696;
    font-size: 15px;
    font-weight: 500;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .4rem 1.25rem;
    clear: both;
    font-weight: 400;
    color: #181c32;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:focus, .dropdown-item:active{
    background: #04aa77;
}



.dropdown-menu {
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 0.9rem;
    color: #3f4254;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0,0,0,.15);
    border-radius: .25rem;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05);
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05);
}

.navbar-toggler{
    border: none;
}

.form-group .form-text {
    font-size: .8rem;
    font-weight: 400;
}

.bg-light {
    background-color: #fff !important;
}

.img-name{
    margin: 0;
    font-size: 13px;
}
.authAside{
    background-color: #eaeaea;
}
@media (min-width: 992px){
.login-form {
    width: 100%;
    max-width: 450px;
}}

@media (max-width: 991.98px){
.login-form {
    width: 100%;
    max-width: 400px;
}}

@media (max-width: 575.98px){
.login-form {
    width: 100%;
    max-width: 100%;
}



}

.card.card-custom>.card-header .card-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: .5rem 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.card.card-custom>.card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
}
.card.card-custom>.card-header .card-title {
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; */
    margin: .5rem;
    margin-left: 0;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0bb783;
    background-color: #fff;
    border:none;
}
.nav-link.active ion-icon{
    color: rgba(0, 0, 0, 0.555);
}
.nav-tabs .nav-link{
    border: none;
    cursor: pointer;
    color: #bdbdbd;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover{
    border: none;
}
.img-upload-btn{
    cursor: pointer;
}

ion-icon {
  font-size: 20px;
  color: #adadad;
  pointer-events: none;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    font-size: 13px;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
    /* background-color: #1bc5bd; */
}
.Toastify__toast--success {
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
}

.spinner{
   display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-left-color: #0bb783;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.group-description, .contact{
    font-size: 13px;
}

.tooltip-inner {
    /* background-color: rgba(0, 0, 0, 0.74); */
    font-size: 12px;
}

.arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.74);
  /* Red */
}

figure.avatar {
    display: inline-block;
    margin-bottom: 0;
    height: 2.3rem;
    width: 2.3rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
figure.avatar .avatar-title {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 19px;
}

figure.avatar .avatar-title ion-icon{
    font-size: 25px;
}

.c_h{
    padding-left: 0 !important;
}



.btn{
    border: none;
}

.s_e{
    color: #dc3545;
    font-size: 80%;
    margin-top: 2px;
}

.s_e ion-icon{
    color: #dc3545;
    font-size: 12px;
    margin-left: 3px;
}
.side-nav{
    padding: 0 15px;
}
.side-nav .active{
    color: #000;
    background-color: #e6e6e663;
    border-radius: 3px;
}

.aside{
    width: 250px !important;
    background-color: #F7F7F7;
    height: 100vh;
    transition: .5s;
    z-index: 12;
}

.aside.show{
  left: 0px;  
}
.o_n{
    line-height: 0px;
    display: none;
}

.o_n ion-icon{
    font-size: 40px
}
.navbar-toggler-icon{
    width: 28px;
}

@media (max-width: 768px) {
    .aside{
        left: -250px;
        position: fixed;
        border-top-right-radius: 5px;
    }
    .o_n{
        display: inline-block;
    }
    .ndss{
        display: none;
    }
    .banner-img{
        padding: 0;
    }
    .card-label{
        font-size: 22px;
    }

    .heading h2{
        font-size: 28px;
    }
    .heading p{
        font-size: 14px;
    }

    .c_P_B{
        padding-left: 0;
    }

    .h_l{
    padding-left: 0px !important;
}

.contact-link{
    margin-top: 65px;
}

.authAside {
    display: none !important;
}

}


.aside-overlay{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.09);
    z-index: 10;
    transition: 1s;
    /* -webkit-backdrop-filter: saturate(180%) blur(2px);
    backdrop-filter: saturate(180%) blur(2px); */
}

.footer{
    background-color: #fff;
    padding: 20px 0;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 13px;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
 font-size: 13px;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  font-size: 13px;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  font-size: 13px;
}

.banner{
    width: 100%;
    background-size: cover;
    background-position: center;
    color: #fff;
}

.banner-img{
    padding-top: 40px;
    padding-bottom: 40px;
}

.banner-img img{
    border-radius: 5px;
}

.heading p{
    /* font-size: 13px; */
    color: #A6A6A6;
}

.d_h .active{
    background: #0bb7833b !important;
    border-radius: 3px;
}

.d_h ion-icon{
    color: #0BB783 !important;
}

@media only screen and (max-width: 480px){
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right{
        top: 4px;
    }

    .Toastify__toast {
    margin-bottom: 4px;
}
    .Toastify__toast-container {
        width: 98vw;
        padding: 0;
        left: 3px;
        margin: 0;
    }
}

.banner-heading{
    font-weight: 900;
}

.welcome{
    background-color: #fff;
}

.m_s h1{
    font-weight: 700;
}
.m_s{
margin: 90px 0;
}

.h_l{
    padding-left: 100px;
}

.sec_1, .sec_2{
    margin: 80px 0;
}
footer{
    border-top: 1px solid #bdbdbd;
}
footer a{
    color: rgb(70 70 70 / 80%);
}

.con_lft p, .con_lft a{
    color:  rgb(70 70 70 / 80%);
}

.copyright{
    color: #bdbdbd;
    font-size: 12px;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
    background-color: #0bb783 !important;
}

.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    
    background-color: #0bb783 !important;
}

.react-datepicker__close-icon::after{
     background-color: #0bb783 !important;
 }
.date-picker.form-control{
    font-size: 13px;
}