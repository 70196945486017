body {
   font-family: Poppins,Helvetica,sans-serif !important;
    color: #3f4254;
    font-size: 15px!important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
 
body::-webkit-scrollbar-track {
 /* background-color: #dbdbdb; */
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
  background-color: #a0a0a0;
  /* background-color: #0bb783; */
  /* outline: 1px solid slategrey; */
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(139, 139, 139);
}